import React from 'react';
import styled from 'styled-components';
import {colors, devices, gridMaxWidth} from '../../../styles/theme';
import {H1, H5, GridComponentContainer, TextContainer} from '../../atoms';
import {hex2rgb} from '../../../utils/hex2rgb';
import {MediaWithText} from '../blocks';
import {CSSTransition} from 'react-transition-group';

const LinksContainer = styled(TextContainer)`
  grid-column: 1 / 5;
  padding: 16px 0;
  ${H1} {
    padding: 0 24px;
    color: ${colors.blue};
    margin-bottom: 16px;
  }
  @media ${devices.tabletLandscape} {
    padding: 20px 0;
    flex-direction: column;
    grid-column: 1 / 4;
  }
  @media ${devices.desktop} {
    padding: 24px 0;
    flex-direction: column;
    grid-column: 1 / 8;
    ${H1} {
      padding: 0 32px;
      color: ${colors.blue};
      margin-bottom: 24px;
    }
  }
  `;

const MediaContainer = styled.div`
  position: relative;
  grid-column: 1 / 5;
  @media ${devices.tabletLandscape}{
    grid-column: 4 / 9;
  }
  @media ${devices.desktop}{
    grid-column: 8 / 17;
  }
`;

const Container = styled(GridComponentContainer)`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(4, 1fr);
  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(8, 1fr);
  }
  @media ${devices.desktop} {
    grid-template-columns: repeat(16, 1fr);
  }
`;

const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media ${devices.tabletLandscape} {
    padding: 0 32px;
    flex-direction: column;
  }
`;

const SlideLink = styled.div`
  text-decoration: none;
  color: ${hex2rgb(colors.grey)};
  width: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  margin: 20px 0;
  ${H5} {
    width: fit-content;
    position: relative;
    transition: all 150ms;
    ${({selected}) => `
      color: ${selected ? `${colors.blue} !important` : colors.xanadu};
      &::after {
        border-bottom: 1px solid ${selected ? `${colors.blue} !important` : colors.xanadu};
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        transform: scale(${selected ? 1 : 0});
        transition: transform 150ms;
      }
      `}
  }
  &:hover {
    ${H5} {
      color: ${hex2rgb(colors.lightBlue)};
      &:after {
        transform: scale(1);
        border-bottom: 1px solid ${({selected}) => selected ? colors.blue : colors.lightBlue};
      }
    }
  }
  @media (min-width: 800px) {
    width: 25%;
  }
  @media ${devices.tabletLandscape} {
    margin: 24px 0;
    width: 100%;
    justify-content: flex-start;
  }
`;

const MarketInfo = styled(MediaWithText)`
  margin: 0 0 24px;
  transition: opacity 800ms;
  @media ${devices.tabletLandscape} {
    padding: 0 16px;
  }
  @media (min-width: ${gridMaxWidth + 200}px) {
    padding: 0;
  }
  &.fade-appear {
    opacity: 0.3;
  }
  &.fade-appear-active {
    opacity: 1;
  }
  &.fade-appear-done {
    opacity: 1;
  }
`;

const MarketSection = ({markets, listTitle, appearAnimation}) => {
  const slides = markets.map(market => ({
    title: market.displayName,
    media: market.header?.media,
    text: market.intro?.text?.body ?? '',
    button: {
      type: 'secondary',
      text: 'Read more',
      link: market.slug,
    }
  }));
  const [selectedSlide, setSelectedSlide] = React.useState(slides ? slides[0] : {});
  return (
    <Container
      fromColumn={1}
      widthInColumns={8}
      appearFrom={appearAnimation?.appearFrom}
    >
      <LinksContainer>
        <H1>{listTitle}</H1>
        <Links>
          {slides.map((slide, index) => (
            <SlideLink
              key={slide.title}
              onClick={() => setSelectedSlide(slides[index])}
              selected={slide.title === selectedSlide?.title}
            >
              <H5>{slide.title}</H5>
            </SlideLink>
          ))}
        </Links>
      </LinksContainer>
      <MediaContainer>
        <CSSTransition
          key={selectedSlide?.title}
          timeout={800}
          classNames='fade'
          in
          appear
        >
          <MarketInfo
            mediaComponent={selectedSlide?.media}
            mediaPosition="top"
            textComponent={{
              title: selectedSlide?.title,
              body: selectedSlide?.text
            }}
            button={selectedSlide?.button}
            fullWidth
          />
        </CSSTransition>
      </MediaContainer>
    </Container>
  );
};

export default MarketSection;
