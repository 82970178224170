import React from 'react';
import styled, {css} from 'styled-components';
import useGridWidth from '../../../hooks/useGridWidth';
import {colors, devices} from '../../../styles/theme';
import {
  GridComponentContainer,
  H1,
  H4,
  RichText,
  Button,
  Media,
  TextContainer,
  Link
} from '../../atoms';
import useResize from '../../../hooks/useResize';
import {getRefDimensions} from '../../../utils/dimensions';

const Container = styled(GridComponentContainer)`
  padding: 24px 16px;
  background: ${colors.lightGrey};
  ${H1} {
    color: ${colors.blue};
    margin: 24px 24px 32px;
  }
`;

const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  @media ${devices.tabletPortrait} {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: auto;
  }
  @media ${devices.tabletLandscape} {
    grid-gap: 24px;
  }
  @media ${devices.desktopXL} {
    ${({gridwidth}) => `grid-template-rows: repeat(2, ${(gridwidth - 32 - 32 - 32 - 32) / 4}px)`};
    grid-gap: 32px;
  }
`;

const FeaturedTile = styled(Link)`
  text-decoration: none;
  background: white;
  grid-column: 1 / 5;
  grid-row: 1 / 2;
  display: inline-flex;
  flex-direction: column;
  transition: box-shadow 200ms;
  &:hover {
    box-shadow: 0 0 32px 0 rgba(77,81,88,0.17);
  }
  ${TextContainer} {
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    ${H4} {
      font-weight: normal;
      color: ${colors.blue};
      margin-bottom: 8px;
    }
    ${Button} {
      width: fit-content;
      margin-top: auto;
    }
    & > div {
      margin-bottom: 8px;
    }
  }
  @media ${devices.tabletPortrait} {
    grid-row: 1 / 2;
    grid-column: 1 / 9;
    ${TextContainer} {
      padding-top: 20px;
      padding-bottom: 20px;
      ${H4} {
        margin-bottom: 12px;
      }
      & > a {
        margin-top: auto;
      }
      & > div {
        margin-bottom: 12px;
      }
    }
  }
  @media (min-width: 775px) {
    flex-direction: row;
    & > div {
      width: 50%;
    }
  }
  @media ${devices.desktop} {
    grid-row: 1 / 2;
    grid-column: 1 / 9;
    flex-direction: row;
    & > div {
      width: 50%;
    }
  }
  @media ${devices.desktopL} {
    flex-direction: column;
    grid-column: 1 / 5;
    grid-row: 1 / 3;
    & > div {
      width: 100%;
      flex: 1;
    }
    ${TextContainer} {
      padding-top: 24px;
      padding-bottom: 24px;
      ${H4} {
        margin-bottom: 16px;
      }
      & > a {
        margin-top: auto;
      }
      & > div {
        margin-bottom: 16px;
      }
    }
  }
`;

const smallTileStyles = css`
  background: white;
  grid-column: span 4;
  ${TextContainer} {
    height: 100%;
    padding: 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    ${H4} {
      font-weight: normal;
      color: ${colors.blue};
      margin-bottom: 6px;
    }
    ${Button} {
      width: fit-content;
      margin-top: auto;
    }
    & > div {
      margin-bottom: 6px;
    }
  }
  @media ${devices.tabletPortrait} {
    ${TextContainer} {
      padding: 16px;
      ${H4} {
        margin-bottom: 8px;
      }
      & > div {
        margin-bottom: 8px;
      }
    }
  }
  @media ${devices.desktop} {
    grid-column: span 2;
    ${TextContainer} {
      padding: 16px;
      ${H4} {
        margin-bottom: 12px;
      }
      & > div {
        margin-bottom: 12px;
      }
    }
  }
  @media ${devices.desktopL} {
    // first child is the featured tile
    &:nth-child(2) {
      grid-column: 5 / 7; grid-row: 1 / 2;
    }
    &:nth-child(3) {
      grid-column: 7 / 9; grid-row: 1 / 2;
    }
    &:nth-child(4) {
      grid-column: 5 / 7; grid-row: 2 / 3;
    }
    &:nth-child(5) {
      grid-column: 7 / 9; grid-row: 2 / 3;
    }
    ${TextContainer} {
      padding: 24px;
      ${H4} {
        margin-bottom: 16px;
      }
      & > div {
        margin-bottom: 16px;
      }
    }
  }
`;

const SmallTileWithLink = styled(Link)`
  text-decoration: none;
  transition: box-shadow 200ms;
  &:hover {
    box-shadow: 0 0 32px 0 rgba(77,81,88,0.17);
  }
  ${smallTileStyles}
`;

const SmallTile = styled.div`
  ${smallTileStyles}
`;

const MediaContainer = styled.div`
  height: 100%;
  & > div {
    height: 100%;
  }
`;

const TilesSection = ({data}) => {
  const gridWidth = useGridWidth();
  const [featuredHeight, setFeaturedHeight] = React.useState();
  const featuredRef = React.useRef();
  const resizeTrigger = useResize();
  React.useEffect(() => {
    setFeaturedHeight(getRefDimensions(featuredRef).height);
  }, [resizeTrigger]);
  return (
    <Container appearFrom={null}>
      <H1>{data.title}</H1>
      <TilesContainer gridwidth={gridWidth}>
        {data.featured && (
          <FeaturedTile
            ref={featuredRef}
            to={data.featured.button?.link || ''}
            disabled
          >
            <Media
              media={data.featured.media}
              mediaWidth={gridWidth / 2}
              height={featuredHeight}
            />
            {data.featured.text && <TextContainer>
              <H4>{data.featured?.text?.title}</H4>
              <RichText source={data.featured?.text?.body} />
              {data.featured?.button && (
                <Button type={data.featured.button.type}>
                  {data.featured.button.text}
                </Button>
              )}
            </TextContainer>}
          </FeaturedTile>
        )}
        {data.tiles.map((tile, index) => {
          const WrapperComponent = tile.button?.link ? SmallTileWithLink : SmallTile;
          return (
            <WrapperComponent
              key={tile.id}
              index={index}
              to={tile.button?.link || ''}
            >
              {tile.media?.file || tile.media?.videoEmbedURL
                ? (
                  <MediaContainer>
                    <Media media={tile.media} />
                  </MediaContainer>
                )
                : tile.text
                  ? (
                    <TextContainer>
                      <H4>{tile.text.title}</H4>
                      <RichText source={tile.text.body} />
                      {tile.button && (
                        <Button type={tile.button.type}>
                          {tile.button.text}
                        </Button>
                      )}
                    </TextContainer>
                  )
                  : null
              }
            </WrapperComponent>
          );
        })}
      </TilesContainer>
    </Container>
  );
};

export default TilesSection;
