import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player/file';
import {Body, Button, GridComponentContainer, H1, H5, Subtitle, UspImage, Link} from '../../atoms';
import {getRefDimensions, getWindowDimensions} from '../../../utils/dimensions';
import {colors, devices, gridMaxWidth} from '../../../styles/theme';
import useResize from '../../../hooks/useResize';
import {NativeUSPImage} from '../../atoms/UspImage';

const HeaderContainer = styled(GridComponentContainer)`
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

const MediaContainer = styled.div`
  width: 100%;
  height: 80vh;
  overflow: hidden;
  @media ${devices.tabletPortrait} {
    height: 60vh;
  }
  @media ${devices.tabletLandscape} {
    height: 80vh;
  }
  @media ${devices.desktop} {
    height: 56.25vw;
  }
  @media (min-width: 1600px) {
    height: 900px;
  }
`;

const VideoContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  @media ${devices.desktop} {
    padding-top: 56.25%;
  }
`;

const VideoPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  ${({window}) => `
    width: ${window.width}px;
    height: ${window.height}px;
  `}
  & > video {
    object-fit: cover;
  }
  @media ${devices.desktop} {
    width: 100%;
    height: 100%;
  }
`;

const UspContainer = styled.div`
  z-index: 1;
  background: white;
  padding: 16px 0;
  box-shadow: 0px 0px 32px rgba(77,81,88,0.17);
  display: flex;
  flex-direction: column;
  width: 85%;
  margin-top: -10vh;
  @media ${devices.tabletPortrait} {
    width: 90%;
    ${({uspHeight}) => `margin-top: -${uspHeight * 0.2}px`};
  }
  @media ${devices.tabletLandscape} {
    flex-direction: row;
    ${({uspHeight}) => `margin-top: -${uspHeight * 0.5}px`};
  }
  @media ${devices.desktop} {
    width: 75%;
    ${({uspHeight}) => `margin-top: -${uspHeight * 0.3}px`};
  }
`;

const Usp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  padding: 16px 24px 24px;
  border-bottom: 1px solid ${colors.lightGrey};
  flex: 1;
  &:last-child {
    border-bottom: none;
  }
  @media ${devices.tabletLandscape} {
    width: 33.333%;
    border-right: 1px solid ${colors.lightGrey};
    border-bottom: none;
    &:last-child {
      border-right: none;
    }
  }
  ${H5} {
    flex: 1;
    font-size: 24px;
    margin: 24px;
    color: ${colors.blue};
    margin-bottom: 16px;
    @media ${devices.tabletLandscape} {
      margin-bottom: 20px;
    }
    @media ${devices.desktop} {
      margin-bottom: 24px;
    }
  }
  ${Subtitle} {
    flex: 1;
    color: ${colors.grey};
  }
`;

const TextOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 7.5%;
  @media ${devices.tabletLandscape} {
    padding: 0 5%;
  }
  @media ${devices.desktop} {
    padding: 0 12.5%;
  }
`;

const HeaderTextContainer = styled.div`
  width: 100%;
  ${Body} {
    margin: 16px 0 20px;
  }
  @media ${devices.tabletPortrait} {
    ${Body} {
      margin: 24px 0 32px;
    }
    width: 75%;
  }
  @media ${devices.tabletLandscape} {
    ${Body} {
      margin: 24px 0 32px;
    }
    width: 50%;
  }
`;

const Header = ({header}) => {
  const [videoHeight, setVideoHeight] = React.useState(0);
  const [uspHeight, setUspHeight] = React.useState(0);
  const uspRef = React.useRef();
  const resizeTrigger = useResize();
  const window = getWindowDimensions();
  React.useEffect(() => {
    const window = getWindowDimensions();
    const usp = getRefDimensions(uspRef);
    const videoIntrinsicHeight = Math.min(gridMaxWidth, window.width) / (16 / 9);
    setVideoHeight(Math.min(window.height, videoIntrinsicHeight));
    setUspHeight(usp.height);
  }, [resizeTrigger]);
  return (
    <HeaderContainer>
      <MediaContainer videoHeight={videoHeight}>
        <VideoContainer>
          {header.media?.file && <VideoPlayer
            playing
            muted
            loop
            url={header.media.file.file.publicURL}
            window={window}
            width="100%"
            height="100%"
          />}
          <TextOverlay>
            <HeaderTextContainer>
              <H1 style={{color: header.titleColor}}>{header.title}</H1>
              <Body style={{color: header.subtitleColor}}>{header.subtitle}</Body>
              {header.button && <Link to={header.button.link}>
                <Button type={header.button.type}>
                  {header.button.text}
                </Button>
              </Link>}
            </HeaderTextContainer>
          </TextOverlay>
        </VideoContainer>
      </MediaContainer>
      <UspContainer ref={uspRef} uspHeight={uspHeight}>
        {header.USP.map(usp => (
          <Usp key={usp.id}>
            {usp.image?.file?.extension === 'svg'
              ? <NativeUSPImage
                src={usp.image?.file?.publicURL}
                alt={usp.image?.alternativeText}
              />
              : <UspImage
                fluid={usp.image?.file?.childImageSharp?.fluid}
                alt={usp.image?.alternativeText}
              />
            }
            <H5>{usp.title}</H5>
            <Subtitle>{usp.subtitle}</Subtitle>
          </Usp>
        ))}
      </UspContainer>
    </HeaderContainer>
  );
};

export default Header;
