import React from 'react';
import Img from 'gatsby-image';
import TextTruncate from 'react-text-truncate';
import styled from 'styled-components';
import {colors, devices, gridMaxWidth} from '../../../styles/theme';
import {Button, GridComponentContainer, H1, H6, Link} from '../../atoms';
import {StoryLabel} from '../../molecules';
import {parseDate} from '../../../utils/time';

const StoryContainer = styled.div`
  grid-column: span 4;
  @media ${devices.tabletPortrait} {
    /* padding: 16px; */
    grid-column: span 2;
  }
  @media ${devices.tabletLandscape} {
    grid-column: span 3;
  }
  @media ${devices.desktop} {
    grid-column: span 1;
    padding: 16px;
  }
`;

const Card = styled(Link)`
  display: block;
  text-decoration: none;
  position: relative;
  height: 100%;
  transition: background 0.2s, box-shadow 0.2s;
  &:hover {
    background: white;
    box-shadow: 0 0 32px 0 rgba(77,81,88,0.17);
  }
`;

const CardTitle = styled(H6)`
  font-size: 18px;
  color: ${colors.blue};
  font-weight: bold;
  margin-bottom: 8px;
  @media ${devices.tabletLandscape} {
    font-size: 20px;
  }
`;

const IntroText = styled.div`
  font-size: 14px;
  color: ${colors.xanadu};
  @media ${devices.tabletLandscape} {
    font-size: 16px;
  }
`;

const SectionTitle = styled(H1)`
  padding: 0 16px;
  grid-column: 1 / -1;
  color: ${colors.blue};
  @media ${devices.tabletPortrait} {
    padding: 0;
  }
  @media ${devices.desktop} {
    padding: 0 16px;
  }
  @media (min-width: ${gridMaxWidth + 200}px) {
    padding: 0px;
  }
`;

const NativeImg = styled.img`
  margin: 0;
`;

const FeaturedLabel = styled(StoryLabel)`
  width: fit-content;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
`;

const InfoContainer = styled.div`
  padding: 16px;
  @media ${devices.tabletLandscape} {
    padding: 20px;
  }
  @media ${devices.desktopXL} {
    padding: 24px;
  }
`;

const ReadMoreButton = styled(Button)`
  margin-top: 16px;
  @media ${devices.desktop} {
    display: none;
  }
`;

const StoriesSectionContainer = styled(GridComponentContainer)`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(4, 1fr);
  @media ${devices.tabletPortrait} {
    padding: 0 16px;
  }
  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(6, 1fr);
    grid-column: 2 / 8;
    padding: 0;
    grid-gap: 24px;
  }
  @media ${devices.desktop} {
    grid-row-gap: 24px;
    grid-column-gap: 0;
    padding: 0;
    grid-template-columns: repeat(4, 1fr);
    grid-column: 1 / -1;
  }
  @media (min-width: ${gridMaxWidth + 200}px) {
    padding: 0px;
  }
`;

const HomepageItem = ({label, image, title, intro, date, link}) => (
  <StoryContainer>
    <Card to={link}>
      <FeaturedLabel color={colors.orange}>
        {label}
      </FeaturedLabel>
      {image?.file?.extension === 'svg'
        ? <NativeImg
          src={image?.file?.publicURL}
          alt={image?.alternativeText}
        />
        : <Img
          fluid={{...image?.file?.childImageSharp?.fluid, aspectRatio: 4 / 3}}
          alt={image?.alternativeText}
        />
      }
      <InfoContainer>
        <CardTitle>{title}</CardTitle>
        {date && <IntroText>{parseDate(date)}</IntroText>}
        {intro && <TextTruncate
          line={4}
          element={IntroText}
          truncateText="…"
          text={intro.text.body}
        />}
        <ReadMoreButton>
          Read more
        </ReadMoreButton>
      </InfoContainer>
    </Card>
  </StoryContainer>
);

const HomepageStory = ({story}) => (
  <HomepageItem
    label="STORY"
    image={story.storyImage}
    title={story.title}
    intro={story.intro}
    link={`/stories${story.slug}`}
  />
);

const HomepageNewsItem = ({newsItem}) => (
  <HomepageItem
    label="NEWS"
    image={newsItem.image}
    date={newsItem.createdAt}
    title={newsItem.title}
    link={`/news${newsItem.slug}`}
  />
);

const StoriesSection = ({title, stories}) => (
  <>
    <StoriesSectionContainer widthInColumns={8}>
      <SectionTitle>{title}</SectionTitle>
      {stories.map(({story, newsItem}) => (
        story ? (
          <HomepageStory key={story.id} story={story}/>
        ) : newsItem ? (
          <HomepageNewsItem key={newsItem.id} newsItem={newsItem}/>
        ) : (
          null
        )
      ))}
    </StoriesSectionContainer>
  </>
);

export default StoriesSection;

