import {graphql, useStaticQuery} from 'gatsby';

const useHomepageData = () => {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        homePage {
          SEO {
            ...SEO
          }
          header {
            ...HomepageHeader
          }
          tiles {
            ...Tiles
          }
          contactUs {
            ...ContactUs
          }
          markets {
            listTitle
            appearAnimation {
              appearFrom
            }
          }
          storiesTitle
          stories {
            story {
              ...Story
            }
            newsItem {
              ...NewsItem
            }
          }
        }
        markets (sort: "displayOrder:asc") {
          displayName
          slug
          header {
            media {
              ...Media
            }
          }
          intro {
            text {
              body
            }
          }
        }
      }
    }
  `);

  return data.strapi;
};

export default useHomepageData;
