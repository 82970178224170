import React from 'react';
import {gridMaxWidth} from '../styles/theme';
import {getWindowDimensions} from '../utils/dimensions';
import useResize from './useResize';

const useGridWidth = () => {
  const resizeTrigger = useResize();
  const [gridWidth, setGridWidth] = React.useState(0);
  React.useEffect(() => {
    const windowWidth = getWindowDimensions().width;
    setGridWidth(Math.min(gridMaxWidth, windowWidth));
  }, [resizeTrigger]);
  return gridWidth;
};

export default useGridWidth;

