import React from 'react';
import useHomepageData from '../queries/useHomepageData';
import Header from '../components/organisms/homepage/Header';
import MarketSection from '../components/organisms/homepage/MarketSection';
import TilesSection from '../components/organisms/homepage/TilesSection';
import {NavigationContext} from '../services/navigation';
import {ContactUs} from '../components/organisms/blocks';
import {PageContent} from '../components/organisms/Layout';
import StoriesSection from '../components/organisms/homepage/StoriesSection';
import {SEO} from '../components/organisms';

const HomePage = () => {
  const data = useHomepageData();
  const {setLocation} = React.useContext(NavigationContext);
  React.useEffect(() => {
    setLocation(window.location.pathname);
  }, []);
  const {homePage, markets} = data;
  const {
    header,
    tiles,
    stories,
    storiesTitle,
    contactUs,
    SEO: _SEO,
    markets: homepageMarkets
  } = homePage || {};
  return (
    <PageContent>
      <SEO
        title={_SEO?.title}
        description={_SEO?.description}
        meta={[
          {
            name: 'keywords',
            content: _SEO?.keywords,
          },
          {
            property: 'og:image',
            content: `${process.env.GATSBY_CLIENT_ORIGIN}${header?.media?.file?.file?.publicURL}`
          }
        ]}
      />
      {header && <Header header={header} />}
      {markets && (
        <MarketSection
          markets={markets}
          listTitle={homepageMarkets?.listTitle}
          appearAnimation={homepageMarkets?.appearAnimation}
        />
      )}
      {tiles && <TilesSection data={tiles} />}
      {stories && (
        <StoriesSection
          title={storiesTitle}
          stories={stories}
        />
      )}
      <ContactUs {...contactUs} />
    </PageContent>
  );
};

export default HomePage;
